import React from 'react';
import "../css/index.scss";
import SubscribePopup from "../components/subscribe/SubscribePopup";

const SubscribeForm = (props) => {
    return (
        <SubscribePopup containerClass={'subscribe-form'}
                        showBirthYear={true}
                        showPhone1={true}
                        excludeWebsiteTag={true}
                        hideLogo={false}
                        closeIcon={false}
                        pbgTag={true}/>
    );
}
export default SubscribeForm;
