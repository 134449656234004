import React, {useState} from "react";
import Popup from "../ui/popup/Popup";
import {validateEmail} from "../../utils/articleUtil";
import {STRINGS} from "../../constants/strings";
import cx from "classnames";
import {URL} from "../../constants/urls";

function SubscribePopup(props) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [birthYear, setBirthYear] = useState('');
    const [phone1, setPhone1] = useState('');
    const [tnc, setTnC] = useState(false);
    const [marketing, setMarketing] = useState(false);
    const [error, setError] = useState(null);

    const [loading, setLoading] = useState(false);

    const onClosePopup = () => {
        setName('');
        setEmail('');
        setBirthYear('');
        setPhone1('');
        setTnC(false);
        setMarketing(false);
        props.onPopupClose && props.onPopupClose();
    }

    const onActionClick = async (action) => {
        switch (action) {
            case 'no':
                onClosePopup();
                break;
            case 'yes':
                if(validateForm()) {
                    setLoading(true);
                    let url = `${URL.SERVER}/subscribe?`;
                    url += `name=${name}`;
                    url += `&email=${email}`;
                    props.showBirthYear && (url += `&birthYear=${birthYear}`);
                    props.showPhone1 && (url += `&phone1=${phone1}`);
                    url += `&source=${window.location.pathname}`;
                    url += `&marketing=${marketing}`;
                    props.pbgTag && (url += `&PBG=true`);
                    !props.excludeWebsiteTag && (url += `&website=${STRINGS.SUBSCRIPTION_TAG}`);
                    fetch(url)
                        .then(resp => resp.json())
                        .then(res => {
                            setLoading(false);
                            alert('Successfully subscribed to our newsletter');
                            onClosePopup();
                        });
                }
                break;
        }
    }

    const validateForm = () => {
        if (!name || name.trim() === '') {
            setError('Please enter valid name');
            return false;
        }
        if (!email || !validateEmail(email)) {
            setError('Please enter valid email');
            return false;
        }
        if (props.showBirthYear && !birthYear) {
            setError('Please enter year of birth');
            return false;
        }
        if (props.showPhone1 && !phone1) {
            setError('Please enter phone number');
            return false;
        }
        if (!tnc) {
            setError('Please accept Pension Times’ Terms and Privacy Policy');
            return false;
        }
        return true;
    }

    const containerClassName = cx(
        "subscribe-popup ebook-popup bg-denim-300 rounded-2xl",
        props.containerClass,
    );

    const closeIcon = props.closeIcon !== undefined ? props.closeIcon : true;

    return (
        <Popup onClose={onClosePopup} customClass={''} containerClass={containerClassName} data-datocms-noindex closeIcon={true}>
            <div className={"lg:flex h-full relative "}>
                {closeIcon && <div className={"close-icon bg-denim-300 absolute rounded-full text-3xl text-white font-bold text-center cursor-pointer"} onClick={onClosePopup}>X</div>}
                <div className={"lg:flex-1 bg-subscribe-popup lg:max-w-7/20 w-full min-h-screen-40 bg-cover bg-center rounded-t-2xl lg:rounded-l-2xl lg:rounded-t-none"}/>
                <div className={"lg:flex-1 px-4 md:px-6 text-white"}>
                    {!props.hideLogo &&<div className={"text-center hidden lg:block lg:mt-6"}>
                        <img className={"ebook-logo"} src={'/images/logos/pt-white-logo.png'}/>
                    </div>}
                    <div className={"lg:mt-1 text-xl lg:text-2xl xl:text-3xl mt-2 mb-1 uppercase text-center font-bold leading-snug font-montserrat"}>
                        Stay on Top of the News!
                    </div>
                    <div className={"mb-2 lg:mt-1 text-base italic text-center leading-snug font-arial"} style={{fontSize: '15px'}}>
                        Subscribe to our newsletter and receive the latest articles directly in your mailbox
                    </div>
                    <div className={"xs:my-2"}>
                        <div className={`error-message ${error ? 'block opacity-100' : 'hidden lg:block opacity-0'}`}>{error} &nbsp;</div>
                        <input type="text"
                               className={"popup-input mb-2"}
                               placeholder="Enter your first name*"
                               value={name}
                               onChange={(event) => {
                                    setError(null);
                                    setName(event.target.value);
                                }}/>
                        <input type="email"
                               className={"popup-input mb-2"}
                               placeholder="Enter your email*"
                               value={email}
                               onChange={(event) => {setError(null);setEmail(event.target.value)}}/>
                        {props.showBirthYear && <input type="number"
                                                       className={"popup-input mb-2"}
                                                       placeholder="Enter your year of birth*"
                                                       value={birthYear}
                                                       onChange={(event) => {
                                                           setError(null);
                                                           setBirthYear(event.target.value)
                                                       }}/>}
                        {props.showPhone1 && <input type="number"
                                                    className={"popup-input mb-2"}
                                                    placeholder="Phone1*"
                                                    value={phone1}
                                                    onChange={(event) => {
                                                        setError(null);
                                                        setPhone1(event.target.value)
                                                    }}/>}
                    </div>
                    <div className={'flex items-center italic font-arial leading-snug'} style={{fontSize: '15px'}}>
                        <div><input type="checkbox" id="tnc" defaultChecked={tnc} onChange={() => setTnC(!tnc)}/></div>
                        <label htmlFor="tnc">
                            I accept and acknowledge Pension Times’ <a href="/website-terms-of-use" target="_blank" className={"underline"}>
                            Terms</a> and <a href="/website-privacy-policy" target="_blank" className={"underline"}>Privacy Policy</a>
                        </label>
                    </div>
                    <div className={'flex items-center italic font-arial mt-2 leading-snug'} style={{fontSize: '15px'}}>
                        <div><input type="checkbox" id="marketing" defaultChecked={marketing} onChange={() => setMarketing(!marketing)}/></div>
                        <label htmlFor="marketing">
                            I’m happy for Pension Times to send me selected marketing information which may be of interest to me.
                        </label>
                    </div>
                    <div className={'flex mt-1 mb-2 xs:mt-4 xs:mb-4 font-helvetica text-xl'}>
                        <button onClick={() => {onActionClick('no');}}
                                className={'bg-denim-500 flex-1 rounded-lg py-3 xs:py-2 mr-4 mb-2 w-full subscribe-button-no'}>No, Thanks</button>
                        <button onClick={() => {onActionClick('yes');}}
                                className={'subscribe-button-yes ok bg-yellow-400 py-3 text-denim-500 flex-1 rounded-lg xs:py-2 mb-2 w-full'} disabled={loading}>
                            I’m Ready
                            {loading && (
                                <img
                                    src="/images/loading.svg"
                                    style={{ marginLeft: "5px" }}
                                />
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </Popup>
    );
}

export default SubscribePopup
